// history_recordのクッキーをエンコードする
export function encodeCookieHistoryRecord() {
  encodeIfNotEncoded('history_record');
}

function isEncoded(str) {
  try {
    return str !== decodeURIComponent(str);
  } catch (e) {
    return false;
  }
}

function encodeIfNotEncoded(cookieName) {
  // クッキーを取得
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => String(row).startsWith(`${cookieName}=`))
    ?.split('=')[1];

  // 対象のクッキーがない場合は何もしない
  if (!cookieValue) return;

  // エンコードされているかどうかを判定
  if (!isEncoded(cookieValue)) {
    // エンコードされていない場合のみエンコード
    const encodedValue = encodeURIComponent(cookieValue);
    document.cookie = `${cookieName}=${encodedValue}; path=/`;
  }
}
